<template>
  <el-container>
    <el-main style="overflow: auto !important">
      <q-toolbar
        class="bg-gray-1 text-primary q-my-none shadow-2 light-breadcrumbs"
      >
        <div class="q-pa-none q-gutter-sm pro">
          <q-breadcrumbs>
            <q-breadcrumbs-el
              icon="PROSmart-accountPlus"
              :label="getRes('Document.BreadcrumbsAcceptanceOfTermsOfUse')"
            />
          </q-breadcrumbs>
        </div>
      </q-toolbar>

      <q-banner
        class="bg-error text-white q-ma-sm"
        v-if="$route.params.status === 'Rejected'"
      >
        {{ getRes("System.Message.Error.LightWeightRegistrationRejected") }}
      </q-banner>

      <div v-if="step === 0">
        <light-weight-registration-acceptance-of-terns-of-use
          @next="step = 1"
        />
      </div>

      <div v-if="step === 1">
        <pro-tender-form-render
          mode="Edit"
          :code="workflowCode"
          :tenderId="tenderId"
          :form-data-promise="formDataPromise()"
          @pro-form-submit="redirectToHcmDashboard('/MyDocument')"
        />
      </div>
    </el-main>
  </el-container>
</template>

<script>
import ProTenderFormRender from "@/components/PROSmart/FormBuilder/ProTenderFormRender";
import LightWeightRegistrationAcceptanceOfTernsOfUse from "@/components/PROSmart/ActionMenu/LightWeightRegistrationAcceptanceOfTernsOfUse";
import PROSmartFormMixin from "@/views/mixins/ProSmartForm";

export default {
  name: "LightWeightRegistration1StTime",
  components: {
    LightWeightRegistrationAcceptanceOfTernsOfUse,
    ProTenderFormRender,
  },
  mixins: [PROSmartFormMixin],
  props: {
    tenderId: {
      type: [String, Number],
      required: true,
    },
    tendererId: {
      type: Number,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    hcmUserId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      step: 0,
      info: {},
      workflowCode: "S_T_NewLightReg",
    };
  },
  created() {
    this.$proSmart.tendererUi
      .getLightWeightRegistration1StTimeRecord(this, this.tenderId)
      .then((rs) => {
        if (rs.value !== "") {
          this.updateInfo(
            [
              "yearEstablished",
              "address1",
              "address2",
              "address3",
              "zipCode",
              "registeredCountryRegion",
              "fax",
              "position",
              "tel",
            ],
            JSON.parse(rs.value)
          );
        }

        console.log(rs);
      });
  },
  methods: {
    /**
     * @param {Array<string>}keyName
     * @param {object} obj
     */
    updateInfo(keyName, obj) {
      keyName.forEach((b) => {
        if (Object.prototype.hasOwnProperty.call(obj, b)) {
          this.info[b] = obj[b];
        }
      });
    },
    async formDataPromise() {
      let loadFormData = await this.loadFormData(
        "Edit",
        "",
        this.workflowCode,
        this.tenderId
      );

      return new Promise((resolve) => {
        let obj = this.info;
        obj.tenderId = this.tenderId;
        obj.tendererId = this.tenderer;
        obj.email = this.email;
        obj.tendererHcmCode = this.hcmUserId;
        obj.exEmailReject = loadFormData.exEmailReject;
        obj.exEmailApproval = loadFormData.exEmailApproval;
        obj.inEmail = loadFormData.inEmail;

        resolve(obj);
      });
    },
    redirectToHcmDashboard(path) {
      this.$store.dispatch("menu/removeSubMenu", path);
      this.$router.push({ path: "/Dashboard" });
    },
  },
};
</script>

<style lang="scss" scoped>
.light-breadcrumbs {
  .flex {
    flex-direction: row;
  }
}
</style>
