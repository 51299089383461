import { render, staticRenderFns } from "./LightWeightRegistration1StTime.vue?vue&type=template&id=614427be&scoped=true&"
import script from "./LightWeightRegistration1StTime.vue?vue&type=script&lang=js&"
export * from "./LightWeightRegistration1StTime.vue?vue&type=script&lang=js&"
import style0 from "./LightWeightRegistration1StTime.vue?vue&type=style&index=0&id=614427be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "614427be",
  null
  
)

export default component.exports
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QToolbar,QBreadcrumbs,QBreadcrumbsEl,QBanner});
